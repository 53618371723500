import React, { FC, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { userActions } from '../../redux/slices/userSlice';

const Timer: FC = () => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const token = useSelector((state: RootState) => state.business.token);
  const started = useSelector((state: RootState) => state.user.started);
  const limitSession = started !== undefined ? Date.parse(started) : new Date();

  const startDate = Date.now();

  const getTime = () => {
    const time = Date.now() - startDate;
    const time2 = Date.now() - Number(limitSession);
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));

    if (Math.floor((time / 1000 / 60) % 60) > 60 || Math.floor((time2 / 1000 / 60) % 60) > 60) {
      dispatch(userActions.setMode({ mode: '' }));
      if (token) {
        dispatch(userActions.logout());
        navigate('/login');
      } else {
        dispatch(userActions.setMode({ mode: '' }));
        navigate('/home');
      }
    } else if (Math.floor((time / 1000 / 60) % 60) > 55 || Math.floor((time2 / 1000 / 60) % 60) > 55) {
      if (location.pathname !== '/out' && location.pathname !== '/sofi' && location.pathname) {
        navigate('/out/no');
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ color: '#1b4ed8' }}>
      <h2 className="App-logo-text">
        {('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)}
      </h2>
    </Box>
  );
};

export default Timer;
