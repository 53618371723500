import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import userService from '../../services/UserServices';
import Terms from '../Terms/Terms';
import { Term } from '../../redux/types/terms';
import { userActions } from '../../redux/slices/userSlice';
import termsService from '../../services/TermsServices';
import { Box, TextField, Button, Stack } from '@mui/material';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { Stepper, Step, StepLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const steps = ['Info. Personal', 'Terminos de uso', 'Activar'];
const businessSteps = ['Info. Personal', 'Terminos de uso'];

const UserRegister: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const RUT = params.get('rut') || '';
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [thrombosis, setThrombosis] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [terms, setTerms] = useState<Term | null>(null);
  const [accept, setAccept] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { token, default_department } = useSelector((state: RootState) => state.business);
  useEffect(() => {
    termsService.getTerms().then((response) => setTerms(response));
    // changeRUT();
  }, []);

  const changeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value);
  };
  const changeLastName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLastName(event.target.value);
  };

  const register = async () => {
    const response = await userService.createUser(
      {
        rut: RUT,
        first_name: name,
        last_name: lastName,
        diabetes: false,
        heart_diseases: false,
        other_diseases: '',
        thrombosis,
        business_id: 15,
        phone: null,
      },
      'SF-D4C',
      terms?.id || NaN,
    );
    if (response.user) {
      const { user } = response;
      user.token = response.token;
      if (token !== null && token !== undefined) {
        user.mode = 'active-in';
        user.redirect = 'nordic';
        dispatch(userActions.login(user));
      } else {
        user.redirect = 'nordic';
        dispatch(userActions.login(user));
      }
    } else {
      setSubmitError(response.error);
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const login = () => {
    navigate('/login');
  };

  return (
    <Box sx={{ maxWidth: '100vw', minWidth: '310px', display: 'inline-block', padding: '5%' }}>
      <Box sx={{ width: '100%', marginBottom: '12px' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {(default_department !== undefined ? businessSteps : steps).map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <SwipeableViews disabled index={activeStep}>
        <Box>
          <Button onClick={login} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            value={name}
            onChange={changeName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Nombre"
            variant="outlined"
          />
          <TextField
            value={lastName}
            onChange={changeLastName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Apellido"
            variant="outlined"
          />
          <FormGroup sx={{ marginBottom: '24px' }}>
            <p>
              Marca la casilla si has sido <b>diagnosticado por un médico</b> con alguna de las siguientes enfermedades:
            </p>
            <FormControlLabel
              onChange={() => setThrombosis(thrombosis === false)}
              control={<Checkbox checked={thrombosis} />}
              label="Antencedentes de Trombosis"
            />
          </FormGroup>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            <Button
              disabled={RUT === '' || name === '' || lastName === ''}
              onClick={handleNext}
              size="large"
              sx={{ marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained">
              {'Siguiente →'}
            </Button>
          </Stack>
        </Box>
        <Box>
          <Button onClick={handleBack} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <Terms content={terms?.content || ''} />
          <Box sx={{ marginX: '5vw' }}>
            <FormControlLabel
              sx={{ marginY: '16px' }}
              onChange={() => setAccept(accept === false)}
              control={<Checkbox checked={accept} />}
              label="He leido y acepto los términos y condiciones de uso"
            />
          </Box>
          <Button
            onClick={register}
            size="large"
            sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
            disabled={RUT === '' || name === '' || lastName === '' || accept === false}
            variant="contained">
            EMPEZAR
          </Button>
          {submitError !== '' && <p>{submitError}</p>}
        </Box>
      </SwipeableViews>
    </Box>
  );
};

export default UserRegister;
